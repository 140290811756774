import OBSWebSocket, { EventSubscription } from "obs-websocket-js";

const obs = new OBSWebSocket();

/**
 * SEND COMMAND
 */
export async function sendCommand(command = "", params = {}) {
  try {
    // console.log("Sending command:", command, params);
    return await obs.call(command, params);
  } catch (err) {
    console.log(
      "Oops! Error sending command. Did you remember to capitalize the first letter?",
      command,
      err.message
    );
    return {};
  }
}

obs.on("error", (err) => {
  console.error("Eek! Socket error:", err);
});

/**
 * GET ACTIVE SCENE AND ALL SOURCES
 * Used to build database of actives for store.
 *
 * Async filters update (12.02.24):
 * https://chat.openai.com/share/6ad50405-bb74-43e8-ac7b-8f51533db0fd
 */
export async function getActiveSceneAndAllSources() {
  const { currentProgramSceneName = "", scenes = [] } = await sendCommand("GetSceneList");
  const sceneNames = scenes.map((scene) => scene.sceneName).reverse();

  const sourcesPromises = sceneNames.map(async (sceneName) => {
    const { sceneItems } = await obs.call("GetSceneItemList", {
      sceneName,
    });

    return sceneItems.reduce(async (accPromise, sceneItem) => {
      const acc = await accPromise;
      const { sceneItemEnabled, sceneItemId, sourceName } = sceneItem;

      const sourceFilterList = await obs.call("GetSourceFilterList", {
        sourceName,
      });

      acc.push({
        filters: sourceFilterList?.filters ?? [],
        id: sceneItemId,
        isActive: sceneItemEnabled,
        name: sourceName,
        parentSceneName: sceneName,
      });

      return acc;
    }, []);
  });

  const sources = await Promise.all(sourcesPromises);

  return { activeSceneName: currentProgramSceneName, sources: sources.flat() };
}

/**
 * GET ALL INPUTS
 */
export async function getAllInputs() {
  const { inputs = [] } = await sendCommand("GetInputList");

  const inputsFinal = await Promise.all(
    inputs
      .filter(
        ({ inputKind }) =>
          inputKind === "coreaudio_input_capture" || inputKind === "av_capture_input_v2"
      )
      .map(async ({ inputName }) => {
        const { inputMuted } = await obs.call("GetInputMute", {
          inputName,
        });

        return {
          name: inputName,
          isMuted: inputMuted,
        };
      })
  );

  return { inputs: inputsFinal };
}

/**
 * EXPORT
 */
export default obs;
