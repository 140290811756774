<script>
  import { mapGetters } from "vuex";
  import { sendCommand } from "../AdminPanelOBS/obs";
  import obsConfig from "../../../../../../../config/obs";
  import AdminPanelGridButton from "../../../AdminUI/AdminPanelGridButton.vue";

  export default {
    components: {
      AdminPanelGridButton,
    },
    computed: {
      ...mapGetters({
        getSourcesBySceneName: "obs/getSourcesBySceneName",
      }),
      characterBorisSources() {
        return this.getSourcesBySceneName(obsConfig.SCENES.CHARACTER_BORIS)?.slice(1) || [];
      },
      characterTerrySources() {
        return this.getSourcesBySceneName(obsConfig.SCENES.CHARACTER_TERRY)?.slice(1) || [];
      },
    },
    methods: {
      handleClick({ parentSceneName = "", id = "", isActive = null }) {
        sendCommand("SetSceneItemEnabled", {
          sceneName: parentSceneName,
          sceneItemId: id,
          sceneItemEnabled: !isActive,
        });
      },
    },
  };
</script>

<template>
  <div class="admin-panel-obs-controls-parent">
    <!-- TERRY CONTROLS -->
    <div class="btns mb-2">
      <AdminPanelGridButton
        v-for="source in characterTerrySources"
        :is-active="source.isActive"
        :key="source.name"
        :name="source.name"
        text-primary-color="#2e9f4a"
        @click="handleClick(source)"
      />
    </div>

    <!-- BORIS CONTROLS -->
    <div class="btns">
      <AdminPanelGridButton
        v-for="source in characterBorisSources"
        :is-active="source.isActive"
        :key="source.name"
        :name="source.name"
        text-primary-color="#6244a1"
        @click="handleClick(source)"
      />
    </div>

    <br />
  </div>
</template>

<style lang="scss" scoped>
  .btns {
    display: flex;
    gap: 10px;
    // border: 1px solid red;
  }
</style>
