<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
        required: true,
      },
      icon: {
        type: [String, Array],
        required: false,
      },
      textPrimaryColor: {
        type: String,
        required: false,
      },
    },
    computed: {
      dynamicTextIcon() {
        return Array.isArray(this.icon) ? this.icon[+this.isActive] : this.icon;
      },
      nameFormatted() {
        // Eg. Terry - Talking Head (OBS convention)
        return this.name.replace(" - ", " ").split(" ");
      },
      textLine1() {
        return this.nameFormatted[0] || "";
      },
      textLine2() {
        return this.nameFormatted[1] || "";
      },
      textLine3() {
        return this.nameFormatted[2] || "";
      },
    },
  };
</script>

<template>
  <button
    class="admin-panel-grid-button-parent btn"
    :class="{ 'is-active': isActive, 'has-icon': icon }"
    @click="$emit('click')"
  >
    <div class="btn-text fit-to-width">
      <span
        v-if="textLine1"
        class="btn-text-1"
        :class="{ 'is-single-word-only': !textLine2 }"
        :style="{ color: textPrimaryColor }"
      >
        {{ textLine1 }}
      </span>

      <span v-if="textLine2">{{ textLine2 }}</span>
      <span v-if="textLine3 && !icon">{{ textLine3 }}</span>

      <i
        v-if="icon"
        class="btn-icon"
        :class="dynamicTextIcon"
      ></i>
    </div>
  </button>
</template>

<style lang="scss" scoped>
  .admin-panel-grid-button-parent {
    width: rem-calc(65);
    height: rem-calc(65);
    min-width: rem-calc(65);
    min-height: rem-calc(65);
    padding: rem-calc(3 8 0 8);
    border-radius: rem-calc(10);
    background-color: #111111;
    border-bottom: 3px solid black;
    overflow: hidden;

    &:active {
      background-color: black !important;
      border-bottom: 3px solid black !important;
    }

    &.is-active {
      background-color: black;
    }

    &.has-icon {
      padding: rem-calc(4 12 0 12);
    }
  }

  .admin-panel-grid-button-parent.is-active .btn-text {
    color: #fe0200;
    opacity: 1;
  }

  .admin-panel-grid-button-parent.is-active .btn-text-1 {
    color: #5c0100 !important;
  }

  // Single word should have brightest red
  .admin-panel-grid-button-parent.is-active .btn-text-1.is-single-word-only {
    color: #fe0200 !important;
  }

  .btn-text {
    font-family: "Avenir Next Condensed";
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(white, 0.5);
    opacity: 0.3;
  }

  .btn-text-1 {
    font-weight: 400;
    // color: green;
  }

  .btn-icon {
    margin: 2px auto 0 auto;
    display: block;
    font-size: rem-calc(20);
  }

  // https://codepen.io/Mitch-Hour/pen/abMBLae
  @supports (animation-timeline: view(inline)) {
    .fit-to-width {
      font-size: 30vh;
      overflow: hidden;
      scroll-snap-type: both mandatory;

      & > * {
        display: block;
        contain: layout;
        inline-size: max-content;
        scroll-snap-align: start;
        line-height: 0.9;
        transform-origin: 0 0;
        animation: apply-text-ratio linear;
        animation-timeline: view(inline);
        animation-range: entry-crossing;
      }
    }
  }

  @keyframes apply-text-ratio {
    from {
      transform: scale(0);
      margin-block-end: -1lh;
    }
  }
</style>
